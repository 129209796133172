import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconArrowHead,
  OutsideClickHandler,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import { currencyData } from '../../../../config/configStripe';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserCurrency } from '../../../../ducks/user.duck';
import CountryFlag from 'react-world-flags';
import CartIcon from '../../../../components/CartIcon/CartIcon';
import { createResourceLocatorString } from '../../../../util/routes';
import css from './TopbarDesktop.module.css';
import Cookies from 'js-cookie';
import { isAuthenticatedSelector } from '../../../../ducks/auth.duck';
import { updateProfile } from '../../../ProfileSettingsPage/ProfileSettingsPage.duck';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({
  currentPage,
  currentUser,
  onLogout,
  showChangeProfileIcon,
  profilePageLinkProps,
}) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  return (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar
          className={css.avatar}
          user={currentUser}
          disableProfileLink
          showChangeProfileIcon={showChangeProfileIcon}
        />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(
              css.menuLink,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(
              css.menuLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.menuLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfilePage">
          <NamedLink
            className={classNames(
              css.menuLink,
              currentPageClass('ProfilePage')
            )}
            {...profilePageLinkProps}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profilePageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const CurrencySelector = ({
  currencyOptions,
  onSelect,
  currentUserCurrency,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [selectedOption, setSelectedOption] = useState(currentUserCurrency);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const updatedOptions = currencyOptions.reduce((acc, option) => {
    if (option.code === currentUserCurrency) {
      acc.unshift(option);
    } else {
      acc.push(option);
    }
    return acc;
  }, []);

  const userInitialCurrencyData = currencyOptions.find(
    o => o.code === currentUserCurrency
  );
  useEffect(() => {
    setSelectedOption(userInitialCurrencyData);
  }, [userInitialCurrencyData]);

  const handleOptionClick = option => {
    const { code } = option || {};

    setSelectedOption(option);
    setIsDropdownOpen(false);
    onSelect(code);

    if (!isAuthenticated) {
      Cookies.set('userCurrency', code, {
        expires: 30,
        secure: true,
      });
    } else {
      dispatch(updateProfile({ publicData: { currency: code } }));
      dispatch(setCurrentUserCurrency(code));
    }
  };

  return (
    <div className={css.dropdown}>
      <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
        <div
          className={classNames(
            css.selectedOption,
            isDropdownOpen ? css.selectedOptionOpen : css.selectedOptionClose
          )}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className={css.userCurrency}>
            <span>
              <CountryFlag
                code={selectedOption.countryCode}
                height="20"
                width="20"
              />
            </span>
            <span>{selectedOption.code}</span>
          </div>
          <IconArrowHead
            direction="down"
            size="small"
            rootClassName={css.arrowIcon}
          />
        </div>
        {isDropdownOpen && (
          <div className={css.optionsContainer}>
            <div className={css.option}>
              <span>Currency</span>
            </div>
            {updatedOptions.map(option => (
              <div
                key={option.code}
                className={classNames(
                  selectedOption.code == option.code && css.activeOptions,
                  css.option
                )}
                onClick={() =>
                  handleOptionClick({
                    code: option.code,
                    countryCode: option.countryCode,
                  })
                }
              >
                <span>
                  <CountryFlag
                    code={option.countryCode}
                    height="20"
                    width="20"
                  />
                </span>
                <span>{option.code}</span>
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

const CartLink = ({ itemsCount }) => {
  return (
    <NamedLink name="CartPage" className={css.cart}>
      <CartIcon itemsCount={itemsCount} />
    </NamedLink>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentUserCurrency,
    itemsCount,
    showChangeProfileIcon,
    profilePageLinkProps,
    notShowDesktopSearchBar,
    history,
    routeConfiguration
  } = props;

  const [mounted, setMounted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setMounted(true);
  }, []);

  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowSearchBar(window.scrollY >= 450);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      currentPage={currentPage}
      currentUser={currentUser}
      onLogout={onLogout}
      showChangeProfileIcon={showChangeProfileIcon}
      profilePageLinkProps={profilePageLinkProps}
    />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage(
          { id: 'TopbarDesktop.logo' },
          { marketplaceName }
        )}
      // linkToExternalSite={config?.topbar?.logoLink}
      />
 <div className={classNames( css.searchWrapper, !showSearchBar && notShowDesktopSearchBar && css.activeSearch)}>
        {(!notShowDesktopSearchBar || showSearchBar) ?
          <TopbarSearchForm
            className={classNames(css.searchLink, {
              [css.takeAvailableSpace]: giveSpaceForSearch,
            })}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={onSearchSubmit}
            initialValues={initialSearchFormValues}
            appConfig={config}
            onFocus={() => {
              // Requirement to redirect to the search page
              // when user focus input.
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration,
                  {},
                  {}
                )
              );
            }}
          />
          : null}
      </div>

      <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={
          authenticatedOnClientSide || !isAuthenticatedOrJustHydrated
        }
        currencySelector={
          <CurrencySelector
            currencyOptions={currencyData}
            onSelect={currency => dispatch(setCurrentUserCurrency(currency))}
            currentUserCurrency={currentUserCurrency}
          />
        }
      />

      <CartLink intl={intl} itemsCount={itemsCount} />

      {inboxLinkMaybe}
      {profileMenuMaybe}
      {signupLinkMaybe}
      {loginLinkMaybe}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
