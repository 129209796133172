/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CountryFlag from 'react-world-flags';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  OutsideClickHandler,
  PhoneNumber,
} from '../../../../components';

import { setCurrentUserCurrency } from '../../../../ducks/user.duck';
import {
  currencyData,
} from '../../../../config/configStripe';
import { useDispatch } from 'react-redux';
import CartIcon from '../../../../components/CartIcon/CartIcon';
import MobileBottomNavbar from '../../../../components/MobileBottomNavbar/MobileBottomNavbar';
import css from './TopbarMobileMenu.module.css';

const phoneNumber = process.env.REACT_APP_TEAM_PHONE_NUMBER;

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route, phoneNumber, className } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId =>
      hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab =>
      hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) ||
      isInboxPage(route?.params?.tab) ||
      isCurrentPage
      ? css.currentPage
      : null;
  };

  if (type === 'phoneNumber') {
    return <PhoneNumber phoneNumber={phoneNumber} className={className} />
  }

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const CurrencySelector = ({
  currencyOptions,
  onSelect,
  currentUserCurrency,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const userInitialCurrencyData = currencyOptions.find(
    o => o.code === currentUserCurrency
  );

  useEffect(() => {
    setSelectedOption(userInitialCurrencyData);
  }, [userInitialCurrencyData]);

  const handleOptionClick = option => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    onSelect(option.code);
  };

  const updatedOptions = currencyOptions.reduce((acc, option) => {
    if (option.code === currentUserCurrency) {
      acc.unshift(option);
    } else {
      acc.push(option);
    }
    return acc;
  }, []);

  return (
    <div className={css.dropDownWrapper}>
      <div className={css.dropdown}>
        <div
          className={classNames(
            css.selectedOption,
            isDropdownOpen ? css.selectedOptionOpen : css.selectedOptionClose
          )}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className={css.userCurrency}>
            <span>
              <CountryFlag
                code={selectedOption.countryCode}
                height="20"
                width="20"
              />
            </span>
            <span>{selectedOption.code}</span>
          </div>
          <span>
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none">
              <path
                d="M0.51605 0.547944C0.95205 0.101944 1.55905 0.0669441 2.09205 0.547944L6.00005 4.29494L9.90805 0.547944C10.4411 0.0669441 11.0491 0.101944 11.4821 0.547944C11.9181 0.992944 11.8901 1.74494 11.4821 2.16294C11.0761 2.58094 6.78705 6.66494 6.78705 6.66494C6.57005 6.88794 6.28505 6.99994 6.00005 6.99994C5.71505 6.99994 5.43005 6.88794 5.21105 6.66494C5.21105 6.66494 0.92405 2.58094 0.51605 2.16294C0.10805 1.74494 0.0800499 0.992944 0.51605 0.547944Z"
                fill="#6B7280"
              />
            </svg>
          </span>
        </div>
        <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
          {isDropdownOpen ? (
            <div className={css.optionsContainer}>
              <div className={css.option}>
                <span>Currency</span>
              </div>
              {updatedOptions.map(option => (
                <div
                  key={option.code}
                  className={classNames(
                    selectedOption.code == option.code && css.activeOptions,
                    css.option
                  )}
                  onClick={() =>
                    handleOptionClick({
                      code: option.code,
                      countryCode: option.countryCode,
                    })
                  }
                >
                  <span>
                    <CountryFlag
                      code={option.countryCode}
                      height="20"
                      width="20"
                    />
                  </span>
                  <span>{option.code}</span>
                </div>
              ))}
            </div>
          ) : null}
        </OutsideClickHandler>
      </div>
    </div>
  );
};

const CartLink = ({ itemsCount }) => {
  return (
    <NamedLink name="CartPage" className={css.cart}>
      <CartIcon className={css.cartIconMobile} itemsCount={itemsCount} />
    </NamedLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    currentUserCurrency,
    itemsCount,
    showChangeProfileIcon,
    profilePageLinkProps,
  } = props;

  const dispatch = useDispatch();
  const user = ensureCurrentUser(currentUser);

  // Copy the original array to avoid direct modification
  const updatedCustomLink = [...customLinks];

  // Splice in the new element at position 1
  updatedCustomLink.splice(1, 0, {
    type: 'phoneNumber',
    phoneNumber,
    className: css.phoneNumber,
  });

  // Map over the updated array to render the components
  const extraLinks = updatedCustomLink.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text || linkConfig.phoneNumber} // Ensure unique keys
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );  });

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.signupOrLogin"
          values={{ signup, login }}
        />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.customLinksWrapper}>{extraLinks}</div>
          <div className={css.spacer} />
          <div>
            <CurrencySelector
              currencyOptions={currencyData}
              onSelect={currency => dispatch(setCurrentUserCurrency(currency))}
              currentUserCurrency={currentUserCurrency}
            />
          </div>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
        <MobileBottomNavbar />
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge
        className={css.notificationBadge}
        count={notificationCount}
      />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage =
      currentPage?.indexOf('InboxPage') === 0 &&
      page?.indexOf('InboxPage') === 0;
    return (currentPage === page) || isAccountSettingsPage || isInboxPage
      ? css.currentPage
      : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  return (
    <div className={css.root}>
      <AvatarLarge
        className={css.avatar}
        user={currentUser}
        showChangeProfileIcon={showChangeProfileIcon}
      />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage
            id="TopbarMobileMenu.greeting"
            values={{ displayName }}
          />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          <CartLink itemsCount={itemsCount} />

          <NamedLink
            className={classNames(
              css.inbox,
              currentPageClass(`InboxPage:${inboxTab}`)
            )}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('ProgilePage')
            )}
            {...profilePageLinkProps}
          >
            <FormattedMessage id="TopbarMobileMenu.profilePageLink" />
          </NamedLink>
        </div>

        <CurrencySelector
          currencyOptions={currencyData}
          onSelect={currency => dispatch(setCurrentUserCurrency(currency))}
          currentUserCurrency={currentUserCurrency}
        />
        <div className={css.customLinksWrapper}>{extraLinks}</div>
        <div className={css.spacer} />
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
      <MobileBottomNavbar />
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
