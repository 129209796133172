import React from 'react';
import css from './CartIcon.module.css'
import classNames from 'classnames';

export default function CartIcon({ itemsCount = 0, className }) {
    return (
        <div className={classNames(css.cartIcon)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={css.icon}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="9" cy="21" r="1"></circle>
                <circle cx="20" cy="21" r="1"></circle>
                <path d="M1 1h4l2.68 13.39A2 2 0 0 0 9.62 17H19"></path>
            </svg>
            <span className={css.itemCount}>{itemsCount}</span>
        </div>
    )
}
